const buildPath = (...elements: string[]) => `/${elements.join('/')}`;

export const SCOPES = {
  globals: 'globals',
  accounts: 'accounts',
  stripe: 'stripe',
  rw_members: 'rw_member',
  employers: 'employers',
  workers: 'workers',
  worker_job_positions: 'worker_job_positions',
  restaurants: buildPath('employers', 'restaurants').slice(1),
  job_positions: buildPath('employers', 'job_positions').slice(1),
  dashboard: 'dashboard',
  graphql: 'graphql',
  organizations: 'organizations',
  companies: buildPath('organizations', 'companies').slice(1)
};

type GenericEndpoint = { [key: string]: string };
interface EndpointType {
  globals: GenericEndpoint;
  accounts: GenericEndpoint;
  rw_members: GenericEndpoint;
  employers: GenericEndpoint;
  restaurants: GenericEndpoint;
  job_positions: GenericEndpoint;
  workers: GenericEndpoint;
  worker_job_positions: GenericEndpoint;
  dashboard: GenericEndpoint;
  organizations: GenericEndpoint;
  companies: GenericEndpoint;
}

export const ENDPOINTS: EndpointType = {
  globals: {
    variables: buildPath(SCOPES.globals, 'variables'),
    status: buildPath(SCOPES.globals, 'status'),
    compressImage: buildPath(SCOPES.globals, 'compress_image')
  },
  accounts: {
    root: SCOPES.accounts,
    me: buildPath(SCOPES.accounts, 'me'),
    login: buildPath(SCOPES.accounts, 'login'),
    setup_checkout_session: buildPath(SCOPES.stripe, 'setup_checkout_session'),
    get_intercom_contact_id: buildPath(SCOPES.accounts, 'intercom_contact_id'),
    employerUserContact: buildPath(SCOPES.accounts, 'employer_user_contact'),
    employerUserContactAccessLink: buildPath(
      SCOPES.accounts,
      'employer_user_contact',
      'access_link'
    ),
    flexibleSlots: buildPath(SCOPES.accounts, 'flexible_slots'),
    flexibleSlotPaymentSession: buildPath(
      SCOPES.accounts,
      'flexible_slots',
      'start_payment_session'
    ),
    freeSlot: buildPath(SCOPES.accounts, 'flexible_slots', 'free_slot'),
    activateFlexibleSlot: buildPath(SCOPES.accounts, 'flexible_slots', 'activate'),
    hasActiveFlexibleSlot: buildPath(SCOPES.accounts, 'flexible_slots', 'has_active_slot'),
    secondaryContacts: buildPath(SCOPES.accounts, 'secondary_contacts'),
    annualSubscription: buildPath(SCOPES.accounts, 'subscriptions', 'annual')
  },
  rw_members: {
    root: SCOPES.rw_members,
    wallet: buildPath(SCOPES.rw_members, 'wallet', 'csm'),
    active_applications: buildPath(SCOPES.rw_members, 'active_job_applications'),
    alert_jps_with_low_shortlisted: buildPath(SCOPES.rw_members, 'alert_jps_with_low_shortlisted'),
    agenda: buildPath(SCOPES.rw_members, 'agenda', 'csm'),
    associated_tasks: buildPath(SCOPES.rw_members, 'tasks', 'csm')
  },
  employers: {
    root: SCOPES.employers,
    graphql: buildPath(SCOPES.graphql, SCOPES.employers),
    picture: buildPath(SCOPES.employers, 'picture'),
    invoices: buildPath(SCOPES.employers, 'invoices'),
    notes: buildPath(SCOPES.employers, 'notes'),
    subscription: buildPath(SCOPES.employers, 'subscription'),
    payment_method: buildPath(SCOPES.employers, 'payment_method'),
    assign_customer_success_manager: buildPath(SCOPES.employers, 'assign_customer_success_manager'),
    employer_contact: buildPath(SCOPES.employers, 'contact'),
    subscription_session: buildPath(SCOPES.employers, 'subscription_session'),
    invoice_pdf: buildPath(SCOPES.employers, 'invoice/pdf'),
    accessLink: buildPath(SCOPES.employers, 'access_link'),
    subscriptionEmail: buildPath(SCOPES.employers, 'subscription', 'email'),
    followups: buildPath(SCOPES.employers, 'followups'),
    employerContacts: buildPath(SCOPES.employers, 'employer_contacts')
  },
  restaurants: {
    root: SCOPES.restaurants,
    localities: buildPath(SCOPES.restaurants, 'locality'),
    picture: buildPath(SCOPES.restaurants, 'picture'),
    employees: buildPath(SCOPES.restaurants, 'employees'),
    members: buildPath(SCOPES.restaurants, 'employees'),
    referentContact: buildPath(SCOPES.restaurants, 'referent_contact')
  },
  job_positions: {
    root: SCOPES.job_positions,
    status: buildPath(SCOPES.job_positions, 'status'),
    assign_tam: buildPath(SCOPES.job_positions, 'assign_posting'),
    assign_csm: buildPath(SCOPES.job_positions, 'assign_customer_success_manager'),
    make_tam_main: buildPath(SCOPES.job_positions, 'set_primary_talent_acquisition_manager'),
    make_csm_main: buildPath(SCOPES.job_positions, 'set_primary_customer_success_manager'),
    remove_tam: buildPath(SCOPES.job_positions, 'remove_talent_acquisition_manager'),
    remove_csm: buildPath(SCOPES.job_positions, 'remove_customer_success_manager'),
    duplicate: buildPath(SCOPES.job_positions, 'duplicate'),
    stats: buildPath(SCOPES.job_positions, 'stats'),
    task: buildPath(SCOPES.job_positions, 'task'),
    application_performance_metrics: buildPath(SCOPES.job_positions, 'application_performance')
  },
  workers: {
    root: SCOPES.workers,
    graphql: buildPath(SCOPES.graphql, SCOPES.workers),
    update_research_status: buildPath(SCOPES.workers, 'update_research_status'),
    active_applications: buildPath(SCOPES.workers, 'active_applications'),
    send_email: buildPath(SCOPES.workers, 'send_email'),
    send_access_email: buildPath(SCOPES.workers, 'send_access_email'),
    upload_cv: buildPath(SCOPES.workers, 'upload_cv'),
    delete_cv: buildPath(SCOPES.workers, 'delete_cv'),
    notes: buildPath(SCOPES.workers, 'notes'),
    similarWorkers: buildPath(SCOPES.workers, 'similar')
  },
  worker_job_positions: {
    root: SCOPES.worker_job_positions,
    graphql: buildPath(SCOPES.graphql, SCOPES.worker_job_positions),
    status: buildPath(SCOPES.worker_job_positions, 'status'),
    index_shortlists: buildPath(SCOPES.worker_job_positions, 'shortlists'),
    shortlist: buildPath(SCOPES.worker_job_positions, 'shortlist'),
    add_worker_to_shortlist: buildPath(SCOPES.worker_job_positions, 'add_worker_to_shortlist'),
    remove_worker_from_shortlist: buildPath(
      SCOPES.worker_job_positions,
      'remove_worker_from_shortlist'
    ),
    job_applications: buildPath(SCOPES.worker_job_positions, 'job_application'),
    worker_status: buildPath(SCOPES.worker_job_positions, 'status'),
    update_status: buildPath(SCOPES.worker_job_positions, 'shortlist', 'status'),
    notes: buildPath(SCOPES.workers, 'notes'),
    shortlists: buildPath(SCOPES.worker_job_positions, 'shortlists'),
    send_shortlist: buildPath(SCOPES.worker_job_positions, 'shortlist', 'send'),
    clean_pending_applications: buildPath(
      SCOPES.worker_job_positions,
      'job_applications',
      'clean_pending'
    ),
    update_worker_shortlist: buildPath(SCOPES.worker_job_positions, 'worker_shortlist', 'add_note')
  },
  dashboard: {
    root: SCOPES.dashboard,
    rw_member_stats: buildPath(SCOPES.dashboard, 'rw_members', 'stats'),
    history_active_job_positions: buildPath(
      SCOPES.dashboard,
      'rw_members',
      'average_active_job_positions'
    ),
    csm_win_rate: buildPath(SCOPES.dashboard, 'rw_members', 'csm_win_rate'),
    average_shortlists_and_workers_sent: buildPath(
      SCOPES.dashboard,
      'rw_members',
      'average_shortlists_and_workers_sent'
    ),
    job_position_average_closing_time: buildPath(
      SCOPES.dashboard,
      'rw_members',
      'job_position_average_closing_time'
    ),
    getAssistanceSatisfactionStatistics: buildPath(
      SCOPES.dashboard,
      'rw_members',
      'get_assistance_satisfaction_statistics'
    )
  },
  organizations: {
    root: SCOPES.organizations,
    paymentMethods: buildPath(SCOPES.organizations, 'payment_methods'),
    structuredNotes: buildPath(SCOPES.organizations, 'structured_notes')
  },
  companies: {
    root: SCOPES.companies,
    subscriptions: buildPath(SCOPES.companies, 'subscriptions'),
    subscriptionEmail: buildPath(SCOPES.companies, 'subscription', 'email')
  }
};

export const TEMPORARY_ENDPOINTS = ENDPOINTS;
// export const ENDPOINTS = {
//   VARIABLES: '/globals/variables',
//   RW_MEMBER: '/rw_member',
//   LOCALITIES: '/employers/restaurants/locality',
//   EMPLOYERS: '/employers',
//   RESTAURANTS: '/employers/restaurants/',
//   EMPLOYERS_PICTURE: '/employers/picture',
//   EMPLOYER_INVOICES: '/employers/invoices/',
//   RESTAURANT_PICTURE: '/employers/restaurants/picture',
//   ASSIGN_TAM: '/employers/job_positions/assign_posting',
//   ASSIGN_CSM: '/employers/job_positions/assign_customer_success_manager',
//   MAKE_CSM_MAIN: '/employers/job_positions/set_primary_customer_success_manager',
//   MAKE_TAM_MAIN: '/employers/job_positions/set_primary_talent_acquisition_manager',
//   REMOVE_TAM: '/employers/job_positions/remove_talent_acquisition_manager',
//   REMOVE_CSM: '/employers/job_positions/remove_customer_success_manager',
//   NOTES: '/employers/notes'
// };
