import { useCallback, useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useScript } from 'usehooks-ts';
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete';

import { GMAPS_API_KEY } from 'config';
import { AddressFieldType } from '@restworld/data-services';

export const useQueryParams = () => {
  const [searchParams] = useSearchParams();

  const getQueryParam = useCallback((id: string) => searchParams.get(id) ?? '', [searchParams]);
  return getQueryParam;
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const useGmapsScript = ({ libraries }: { libraries: string }) => {
  const script = `https://maps.googleapis.com/maps/api/js?key=${GMAPS_API_KEY}&libraries=${libraries}`;
  const gmapsScript = useScript(script);
  useEffect(() => {
    if (gmapsScript === 'error') {
      // eslint-disable-next-line no-console
      console.error('Error loading GMAPS Script');
    }
  }, [gmapsScript]);
  return gmapsScript === 'ready';
};

export const useAddressInput = ({
  onChange,
  controlledValue,
  placesFilterTypes
}: HookPropsType) => {
  const [options, setOptions] = useState<string[]>([]);
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    debounce: 500,
    requestOptions: {
      types: []
    }
  });

  const [selectedOption, setSelectedOption] = useState('');
  const handleSelection = useCallback(
    (value: string) => {
      setSelectedOption(value);
      setValue(value);
      clearSuggestions();
    },
    [clearSuggestions, setValue]
  );

  const renderSuggestions = useMemo(
    () =>
      data.map((suggestion) => {
        const {
          // place_id,
          structured_formatting: { main_text, secondary_text }
        } = suggestion;
        const mainTitle = `${main_text}${secondary_text ? `, ${secondary_text}` : ''}`;
        return mainTitle;
      }),
    [data]
  );

  useEffect(() => {
    setOptions(renderSuggestions);
  }, [renderSuggestions]);

  useEffect(() => {
    if (selectedOption && onChange) onChange(selectedOption);
  }, [selectedOption, onChange]);
  useEffect(() => {
    if (controlledValue) setValue(controlledValue?.toString());
  }, [controlledValue, setValue]);

  return {
    handleSelection,
    renderSuggestions,
    value,
    selectedOption,
    status,
    options,
    setValue
  };
};
type HookPropsType = {
  onChange?: ChangeFnType;
  controlledValue?: string | number;
  placesFilterTypes?: string[];
};
type ChangeFnType = (value: string) => void;

export type UpdatedAddressFieldType = AddressFieldType & { gmaps_place_id?: string };

export const useAddressAdditionalFields = ({ addressString }: { addressString: string }) => {
  const [data, setData] = useState<UpdatedAddressFieldType>({});
  useEffect(() => {
    if (!addressString) return;
    getLocationAddressFields(addressString)
      .then((data) => {
        setData(data as UpdatedAddressFieldType);
      })
      .catch((e) => e);
  }, [addressString]);
  return data;
};
export const getLocationAddressFields = async (addressString?: string) => {
  const data = await getGeocode({ address: addressString });
  const addressComponents: { types: string[]; long_name: string }[] = data[0].address_components;
  const getLnNameValue = (
    key:
      | 'country'
      | 'locality'
      | 'administrative_area_level_1'
      | 'administrative_area_level_2'
      | 'street_number'
  ) => addressComponents.find((c) => c.types.includes(key))?.long_name ?? '';
  const country = getLnNameValue('country');
  const locality = getLnNameValue('locality');
  const administrativeAreaLevelOne = getLnNameValue('administrative_area_level_1');
  const administrativeAreaLevelTwo = getLnNameValue('administrative_area_level_2');
  const street_number = getLnNameValue('street_number');
  const postalCode = getZipCode(data[0], false);
  const { lat, lng } = getLatLng(data[0]);
  const gmapsPlaceId = data[0]?.place_id;
  const addressData = {
    address: addressString,
    address_lat: lat,
    address_lon: lng,
    locality: locality,
    country: country,
    postal_code: postalCode,
    administrative_area_level_1: administrativeAreaLevelOne,
    administrative_area_level_2: administrativeAreaLevelTwo,
    street_number,
    gmaps_place_id: gmapsPlaceId
  };
  return addressData;
};
