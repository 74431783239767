import { EntityId, Employer, EmployerContact, Invoice } from '@restworld/utility-types';
import { ENDPOINTS, IndexEmployers } from '@restworld/data-services';
import { Service } from 'service/baseService';
import dynamicAxios from 'utils/axios';
import { useMemo } from 'react';
import useAuth from 'hooks/useAuth';
import { SubscriptionDuration } from 'components/EmployerNewSubscriptionModal';
import { Id } from '@restworld/data-services/src/lib/utils/common_types';
import { TEMPORARY_ENDPOINTS } from 'constants/api';
import { IndexFollowupsUpdated } from 'pages/showOrganization/employer-followup/employer-followup.context';
import { EmployerUserContact } from '@restworld/utility-types';
import { FollowupsParamsGETUpdated } from 'pages/showOrganization/employer-followup/employer-followup.hooks';

export type FollowupsParamsUpdated = {
  csmResponsibleId: string;
  followupContactMethod?: 'email' | 'whatsapp' | 'phone'; // default: email
  followupStatus?: 'success' | 'not_started' | 'failure'; // default: not_started
  notes?: string;
  organizationId?: string;
  referentContactId?: string;
};

export interface EmployerFilterType {
  csm?: EntityId;
  locality?: string[];
  open_jp?: boolean;
  my_work?: boolean;
  search_vector?: string;
  created_by?: string;
}

export type NoteCategory = 'job_position' | 'restaurant' | 'employer' | 'worker';

export interface FetchSubscriptionFeedbackByOrganization {
  assigned_customer_success_manager: AssignedCustomerSuccessManager;
  employer_user_contact_email: string;
  inserted_at: string;
  motive_for_cancelling_subscription: string;
  organization_id: string;
  reactivate_service: string;
  additional_feedback: string;
  service_satisfaction_score: number;
  service_referral_rating_score: number;
  assistance_satisfaction_score: number;
}

export type AssignedCustomerSuccessManager = {
  icon_url: string;
  name: string;
  surname: string;
};

class ServiceEmployers extends Service {
  fetchEmployers({
    limit,
    offset,
    order_by,
    filters
  }: {
    limit: number;
    offset: number;
    order_by?: string[];
    filters: EmployerFilterType;
  }) {
    return dynamicAxios(this.jwt).get<IndexEmployers>(ENDPOINTS.employers.root, {
      params: { limit, offset, order_by, ...filters }
    });
  }

  showEmployer(employerId: EntityId) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.employers.root}/${employerId}`);
  }

  uploadPicture(employerId: EntityId, file: File) {
    const formData = new FormData();
    formData.append('user_id', employerId);
    formData.append('picture', file);
    return dynamicAxios(this.jwt).post(ENDPOINTS.employers.picture, formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  deletePicture(employerId: EntityId) {
    return dynamicAxios(this.jwt).delete(ENDPOINTS.employers.picture, {
      data: { user_id: employerId }
    });
  }

  createInvoice({ invoice }: { invoice: Partial<Invoice> }) {
    return dynamicAxios(this.jwt).post<Id>(ENDPOINTS.employers.invoices, {
      ...invoice
    });
  }

  fetchInvoices({
    limit,
    offset,
    employerId,
    onlyPaid,
    csmId,
    cancelled,
    organizationId
  }: {
    limit: number;
    offset: number;
    employerId?: string;
    organizationId?: string;
    onlyPaid?: boolean;
    csmId?: string;
    cancelled?: boolean;
  }) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.employers.invoices, {
      params: {
        employer_id: employerId,
        csm_id: csmId,
        limit,
        offset,
        'paid?': onlyPaid,
        'cancelled?': cancelled,
        organization_id: organizationId
      }
    });
  }

  fetchNotes(entityId: EntityId, entityCategory: NoteCategory) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.employers.notes, {
      params: { id: entityId, entity_category: entityCategory }
    });
  }

  insertNote(entityId: EntityId, entityCategory: NoteCategory, note: string) {
    return dynamicAxios(this.jwt).post(getEndpoint(entityCategory), {
      note,
      ...formatEntityId(entityCategory, entityId)
    });
  }

  updateNote(noteId: string, note: string) {
    return dynamicAxios(this.jwt).put(`${ENDPOINTS.employers.notes}/${noteId}`, { note });
  }

  removeNote(noteId: string) {
    return dynamicAxios(this.jwt).delete(`${ENDPOINTS.employers.notes}/${noteId}`);
  }

  indexSubscriptions(employerId: EntityId) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.employers.subscription, {
      params: { employer_id: employerId }
    });
  }

  createSubscriptionSession(employerId: EntityId, duration: SubscriptionDuration, slots: number) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.employers.subscription_session, {
      employer_id: employerId,
      duration,
      slots
    });
  }

  indexPaymentMethods(employerId: EntityId) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.employers.payment_method, {
      params: { employer_id: employerId }
    });
  }

  updateAssignation(employerId: EntityId, assignedTo: string | undefined) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.employers.assign_customer_success_manager, {
      employer_id: employerId,
      assigned_to: assignedTo
    });
  }

  updateEmployer(employerId?: EntityId, params?: Partial<Employer>) {
    return dynamicAxios(this.jwt).put(`${ENDPOINTS.employers.root}/${employerId}`, {
      ...(params || {})
    });
  }

  createEmployerContact(employerContact: Partial<EmployerContact>) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.employers.employer_contact, {
      ...(employerContact || {})
    });
  }

  updateEmployerContact(employerContactId: number, params: Partial<EmployerContact>) {
    return dynamicAxios(this.jwt).patch(
      `${ENDPOINTS.employers.employer_contact}/${employerContactId}`,
      {
        ...(params || {})
      }
    );
  }

  getAccessLink(employerId?: EntityId) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.employers.accessLink, {
      params: { employer_id: employerId }
    });
  }

  searchCompanies({ searchText, countryCode }: { searchText?: string; countryCode: string }) {
    return dynamicAxios(this.jwt).get('/employers/search_companies', {
      params: { search_text: searchText, country_code: countryCode }
    });
  }

  createEmployer(params: Partial<Employer>) {
    return dynamicAxios(this.jwt).post<{ user_id: string }>(ENDPOINTS.employers.root, {
      ...params
    });
  }

  sendSubscriptionEmail({
    employerId,
    paymentLink
  }: {
    employerId?: string;
    paymentLink?: string;
  }) {
    return dynamicAxios(this.jwt).post<string>(TEMPORARY_ENDPOINTS.employers.subscriptionEmail, {
      employer_id: employerId,
      payment_link: paymentLink
    });
  }

  fetchFollowups = (params?: FollowupsParamsGETUpdated) => {
    return dynamicAxios(this.jwt).get<{ data: IndexFollowupsUpdated[] }>(
      `/organizations/followups`,
      {
        params: {
          organization_id: params?.organization_id,
          limit: params?.limit,
          offset: params?.offset
        }
      }
    );
  };

  createEmployerFollowup = (params: Partial<FollowupsParamsUpdated>) => {
    const {
      csmResponsibleId,
      followupContactMethod,
      followupStatus,
      notes,
      referentContactId,
      organizationId
    } = params;
    return dynamicAxios(this.jwt).post<{
      data: IndexFollowupsUpdated;
    }>(`/organizations/followups`, {
      csm_responsible_id: csmResponsibleId,
      organization_id: organizationId,
      referent_contact_id: referentContactId,
      followup_contact_method: followupContactMethod,
      followup_status: followupStatus,
      notes
    });
  };

  updateEmployerFollowup = (id: number, params: Partial<FollowupsParamsUpdated>) => {
    const {
      csmResponsibleId,
      organizationId,
      followupContactMethod,
      followupStatus,
      notes,
      referentContactId
    } = params;
    return dynamicAxios(this.jwt).put<{
      data: IndexFollowupsUpdated;
    }>(`/organizations/followups/${id}`, {
      csm_responsible_id: csmResponsibleId,
      organization_id: organizationId,
      referent_contact_id: referentContactId,
      followup_contact_method: followupContactMethod,
      followup_status: followupStatus,
      notes
    });
  };

  getEmployerContacts = (organizationId: string) => {
    return dynamicAxios(this.jwt).get<{
      employer_contacts: EmployerUserContact[];
    }>(`${ENDPOINTS.employers.employerContacts}`, {
      params: {
        organization_id: organizationId
      }
    });
  };

  fetchFollowup = (id: number) => {
    return dynamicAxios(this.jwt).get<{
      data: IndexFollowupsUpdated;
    }>(`/organizations/followups/${id}`);
  };

  deleteEmployerFollowup = (id: number) => {
    return dynamicAxios(this.jwt).delete<string>(`/organizations/followups/${id}`);
  };

  fetchSubscriptionFeedbackByOrganization(params: { organization_id?: string }) {
    return dynamicAxios(this.jwt).get<FetchSubscriptionFeedbackByOrganization[]>(
      `/employers/fetch_employer_subscription_feedback`,
      {
        params
      }
    );
  }
}

export const getCSMCalendlyLink = (employerId: string) =>
  `${process.env.REACT_APP_HOST_ENDPOINT}${ENDPOINTS.employers.root}/csm_calendly/${employerId}`;

const formatEntityId = (category: string, entityId: string) => {
  return {
    [`${category}_id`]: entityId
  };
};

const getEndpoint = (entityCategory: NoteCategory) => {
  switch (entityCategory) {
    case 'worker':
      return ENDPOINTS.workers.notes;
    default:
      return ENDPOINTS.employers.notes;
  }
};

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceEmployers(jwt), [jwt]);
};
export default useService;
